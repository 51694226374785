@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Press Start 2P', cursive;
}

.game-panel-h {
  height: calc(
    100vh - 110px
  ); /* Przykładowe obliczenie dla całej wysokości viewportu */
}

.play-regular {
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.play-bold {
  font-family: "Play", sans-serif;
  font-weight: 700;
  font-style: normal;
}
